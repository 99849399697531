// storage
import { getAuthToken, setAuthToken } from 'src/storage/AuthToken';

// Used to call logout when token and refresh token are invalid.
import Store from 'src/store';
import authActions from 'src/store/Authentication/actions';

type ResponseType = 'json' | 'text' | 'arraybuffer';

const refresh = async () => {
  const authToken = getAuthToken();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_STAFF_V2_API_ENDPOINT}/api/auth/refresh/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/plain',
          authorization: `Bearer ${authToken}`,
        },
      }
    );
    const responseJson = await response.json();
    if (responseJson.auth_data && responseJson.auth_data.access_token) {
      setAuthToken(responseJson.auth_data.access_token);
    } else {
      const { dispatch } = Store;
      dispatch(authActions.logout());
      dispatch(authActions.setError(new Error('Session expired')));
    }
  } catch (e) {
    throw new Error(e);
  }
};

const request = async (
  url: string,
  options: RequestInit,
  responseType?: ResponseType
): Promise<any> => {
  const sessionToken = getAuthToken();

  const customOptions: RequestInit = {
    ...options,
    headers: {
      ...options.headers,
      ...(sessionToken && { authorization: `Bearer ${sessionToken}` }),
    },
  };

  try {
    const response = await fetch(url, customOptions);

    // Handle token expiration
    if (response.status === 401) {
      await refresh();
      return await request(url, options, responseType);
    }

    if (response.status === 204) {
      return {};
    }

    // Handle errors
    if (!response.ok) {
      let errorMessage = `HTTP error! Status: ${response.status}`;
      try {
        const errorResponse = await response.json();
        errorMessage = errorResponse?.error || errorMessage;
      } catch (err) {
        // If response is not JSON, keep the default error message
      }
      throw new Error(errorMessage);
    }

    // Handle response based on expected type
    switch (responseType) {
      case 'text':
        return await response.text();
      case 'arraybuffer':
        return await response.arrayBuffer();
      case 'json':
        return await response.json();
      default:
        // Auto-detect Blob for Excel files
        const contentType = response.headers.get('content-type');
        if (contentType?.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
          return await response.blob(); // ✅ Return Blob for Excel files
        }
        return await response.json(); // Default to JSON if type is unknown
    }
  } catch (e) {
    console.error(e.message);
    throw e;
  }
};


export default request;
