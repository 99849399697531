function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_APP = '/app';
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_MANAGEMENT = '/management';
const ROOTS_TOOLS = '/tools';
const ROOTS_REPORTS = '/reports';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const MAIN_PATHS = {
  root: path(ROOTS_APP, '/sites'),
  profile: path(ROOTS_APP, '/profile'),
  todo: path(ROOTS_APP, '/todo'),
  example: path(ROOTS_APP, '/example'),
  sites: path(ROOTS_APP, '/sites'),
  edit: path(ROOTS_APP, '/site/:id/edit'),
  bulkAdd: path(ROOTS_APP, '/sites/bulk/add'),
  announcements: path(ROOTS_APP, '/announcements'),
  editAnnouncement: path(ROOTS_APP, '/announcement/:id/edit'),
  createAnnouncement: path(ROOTS_APP, '/announcement/create'),
  processing: path(ROOTS_APP, '/processing'),
  notifications: path(ROOTS_APP, '/notifications'),
  dashboard: {
    sites: {
      root: path(ROOTS_DASHBOARD, '/sites'),
      add: path(ROOTS_APP, '/sites/add'),
      single: path(ROOTS_DASHBOARD, '/site'),
    },
    todo: path(ROOTS_DASHBOARD, '/todo'),
    map: path(ROOTS_DASHBOARD, '/map'),
    announcements: {
      root: path(ROOTS_DASHBOARD, '/announcements'),
      single: path(ROOTS_DASHBOARD, '/edit-announcement'),
    },
  },
  management: {
    users: path(ROOTS_MANAGEMENT, '/users'),
    user: {
      single: path(ROOTS_MANAGEMENT, '/user'),
      add: path(ROOTS_MANAGEMENT, '/user/add'),
    },
    usersBulkAdd: path(ROOTS_MANAGEMENT, '/users/bulk/add'),
    companies: path(ROOTS_MANAGEMENT, '/companies'),
    company: {
      single: path(ROOTS_MANAGEMENT, '/company'),
      add: path(ROOTS_MANAGEMENT, '/company/add'),
    },
    tickets: path(ROOTS_MANAGEMENT, '/tickets'),
    manageAccess: path(ROOTS_MANAGEMENT, '/manage-access'),
  },
  tools: {
    routePlanner: path(ROOTS_TOOLS, '/route-planner'),
    internalTools: path(ROOTS_TOOLS, '/internal-tools'),
    importJobs: path(ROOTS_TOOLS, '/import-jobs'),
    exportJobs: path(ROOTS_TOOLS, '/export-jobs'),
    billingReport: path(ROOTS_TOOLS, '/billing-report'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    editById: path(ROOTS_DASHBOARD, '/user/ada-lindgren/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  reports: {
    qcFailReports: path(ROOTS_REPORTS, '/qc-fail-report'),
  },
  app: {
    sitePlanner: path(ROOTS_APP, '/site-planner'),
  },
};
