import { CompanyContent, CompanyPagination, OwnershipTypeContent, CompanyOptionsContent, CompanyRequestObj } from 'src/@types/company';

export enum CompanyActionType {
  FETCH_ALL_COMPANIES = 'Companies@FETCH_ALL_COMPANIES',
  SET_COMPANIES = 'Companies@SET_COMPANIES',
  FETCH_COMPANY_CONTENT = 'Companies@FETCH_COMPANY_CONTENT',
  SET_COMPANY_CONTENT = 'Companies@SET_COMPANY_CONTENT',
  SET_LIMIT = 'Companies@SET_LIMIT',
  SET_CURSOR = 'Companies@SET_CURSOR',
  SET_ORDERBY = 'Companies@SET_ORDERBY',
  SET_ORDERBY_DIRECTION = 'Companies@SET_ORDERBY_DIRECTION',
  SET_SEARCH = 'Companies@SET_SEARCH',
  SET_SEARCH_FIELD = 'Companies@SET_SEARCH_FIELD',
  SET_IS_VENDOR = 'Companies@SET_IS_VENDOR',
  SET_IS_TOWER_OWNER = 'Companies@SET_IS_TOWER_OWNER',
  SET_LOADING = 'Companies@SET_LOADING',
  SET_ERROR = 'Companies@SET_ERROR',
  CREATE_COMPANY = 'Companies@CREATE_COMPANY',
  UPDATE_COMPANY = 'Companies@UPDATE_COMPANY',
  SET_COMPANY_CONTENT_LOADING = 'Companies@SET_COMPANY_CONTENT_LOADING',
  SET_COMPANY_CREATE_LOADING = 'Companies@SET_COMPANY_CREATE_LOADING',
  SET_COMPANY_UPDATE_LOADING = 'Companies@SET_COMPANY_UPDATE_LOADING',
  SET_COMPANY_CREATE_SUCCESS = 'Companies@SET_COMPANY_CREATE_SUCCESS',
  SET_COMPANY_UPDATE_SUCCESS = 'Companies@SET_COMPANY_UPDATE_SUCCESS',
  SET_COMPANY_REQUEST_OBJ = 'Companies@SET_COMPANY_REQUEST_OBJ',

  // companies options.
  FETCH_COMPANY_OPTIONS = 'Companies@FETCH_COMPANY_OPTIONS',
  SET_COMPANY_OPTIONS = 'Companies@SET_COMPANY_OPTIONS',
  SET_COMPANY_OPTIONS_LOADING = 'Companies@SET_COMPANY_OPTIONS_LOADING',
  SET_COMPANY_SELECTION_ID = 'Companies@SET_COMPANY_SELECTION_ID',

  // ownership types.
  FETCH_OWNERSHIP_TYPES = 'Companies@FETCH_OWNERSHIP_TYPES',
  SET_OWNERSHIP_TYPES_LOADING = 'Companies@SET_OWNERSHIP_TYPES_LOADING',
  SET_OWNERSHIP_TYPES = 'Companies@SET_OWNERSHIP_TYPES',


  // delete company
  SET_COMPANY_DELETE_LOADING = 'Companies@SET_COMPANY_DELETE_LOADING',
  DELETE_COMPANY = 'Companies@DELETE_COMPANY',
  SET_COMPANY_DELETE_SUCCESS = 'Companies@SET_COMPANY_DELETE_SUCCESS',
  DELETE_COMPANY_ASSET_GROUP_PERMISSION = 'Companies@DELETE_COMPANY_ASSET_GROUP_PERMISSION',
  DELETE_COMPANY_CUSTOMER = 'Companies@DELETE_COMPANY_CUSTOMER',
  DELETE_COMPANY_GUEST_ACCESS = 'Companies@DELETE_COMPANY_GUEST_ACCESS',

  // billing report.
  DOWNLOAD_BILLING_REPORT = 'Companies@DOWNLOAD_BILLING_REPORT',
  SET_DOWNLOAD_BILLING_REPORT = 'Companies@SET_DOWNLOAD_BILLING_REPORT',
  SET_DOWNLOAD_BILLING_REPORT_SUCCESS = 'Companies@SET_DOWNLOAD_BILLING_REPORT_SUCCESS',
  SET_DOWNLOAD_BILLING_REPORT_LOADING = 'Companies@SET_DOWNLOAD_BILLING_REPORT_LOADING',
  EMAIL_BILLING_REPORT = 'Companies@EMAIL_BILLING_REPORT',
  SET_EMAIL_BILLING_REPORT = 'Companies@SET_EMAIL_BILLING_REPORT',
  SET_EMAIL_BILLING_REPORT_SUCCESS = 'Companies@SET_EMAIL_BILLING_REPORT_SUCCESS',
  SET_EMAIL_BILLING_REPORT_LOADING = 'Companies@SET_EMAIL_BILLING_REPORT_LOADING',

  CLEAR = 'Companies@CLEAR',
}

export type CompanyState = {
  error?: Error;
  company_pagination?: CompanyPagination;
  company_content?: CompanyContent;
  limit?: number;
  cursor?: string;
  search?: string;
  search_field?: 'name' | 'short_name' | 'abbreviation' | 'users_count';
  order_by_direction?: 'asc' | 'desc';
  order_by?: 'name' | 'short_name' | 'abbreviation' | 'users_count';
  is_tower_owner?: boolean;
  is_vendor?: boolean;
  is_loading?: boolean;
  company_content_loading?: boolean;
  company_create_loading?: boolean;
  company_update_loading?: boolean;
  is_company_create_success?: boolean;
  is_company_update_success?: boolean;
  company_request_obj?: CompanyRequestObj;

  // companies simple.
  company_options?: CompanyOptionsContent;
  is_company_options_loading?: boolean;
  company_selection_id?: string;

  // ownership types.
  ownership_types?: OwnershipTypeContent;
  is_ownership_types_loading?: boolean;

  //delete company
  is_company_delete_loading?: boolean;
  is_company_delete_success?: boolean;

  // billing report.
  downloadBillingReport: any;
  isDownloadBillingReportLoading: boolean;
  isDownloadBillingReportSuccess: boolean;
  emailBillingReport: any;
  isEmailBillingReportLoading: boolean;
  isEmailBillingReportSuccess: boolean;

};

export type CompanyPayload = {
  error?: Error;
  company_pagination?: CompanyPagination;
  company_content?: CompanyContent;
  limit?: number;
  cursor?: string;
  search?: string;
  search_field?: 'name' | 'short_name' | 'abbreviation' | 'users_count';
  order_by_direction?: 'asc' | 'desc';
  order_by?: 'name' | 'short_name' | 'abbreviation' | 'users_count';
  is_tower_owner?: boolean;
  is_vendor?: boolean;
  is_loading?: boolean;
  company_content_loading?: boolean;
  company_create_loading?: boolean;
  company_update_loading?: boolean;
  is_company_create_success?: boolean;
  is_company_update_success?: boolean;
  company_id?: string;
  company_request_obj?: CompanyRequestObj;

  // Simple companies fetch.
  company_options?: CompanyOptionsContent;
  is_company_options_loading?: boolean;
  company_selection_id?: string;

  // ownership types.
  ownership_types?: OwnershipTypeContent;
  is_ownership_types_loading?: boolean;

  //delete company
  is_company_delete_loading?: boolean;
  is_company_delete_success?: boolean;

  // billing report.
  downloadBillingReport?: any;
  isDownloadBillingReportLoading?: boolean;
  isDownloadBillingReportSuccess?: boolean;
  emailBillingReport?: any;
  isEmailBillingReportLoading?: boolean;
  isEmailBillingReportSuccess?: boolean;

};

export type CompanyAction = {
  payload?: CompanyPayload;
  type: CompanyActionType;
};

export type CompanyReducer = (
  state: CompanyState,
  payload?: CompanyPayload
) => CompanyState;
