import { Company } from './company';

export enum ROLE {
  INTERNAL_ADMIN = 'internal_admin',
  CUSTOMER_SUCCESS = 'customer_success',
  UNKNOWN = 'unknown',
};

export type UserOptions = {
  id?: string;
  full_name?: string;
  company_name?: string;
  email?: string;
};


export type UserOptionsContent = {
  users?: UserOptions[];
};

export type User = {
  id?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  email?: string;
  phone_number?: string;
  invited?: boolean;
  date_created?: Date;
  phone_verified?: boolean;
  role: ROLE;
  role_name?: ROLE;
  role_hierarchy?: number;
  role_id?: string;
  can_be_external?: boolean;
};

export type UserWithCompanyContent = {
  id: string;
  first_name: string;
  last_name: string;
  full_name: string;
  email?: string;
  phone_number?: string;
  invited?: boolean;
  date_created?: Date;
  phone_verified?: boolean;
  role: ROLE;
  company?: Company;
  current_company?: Company;
  role_name?: ROLE;
  role_hierarchy?: number;
  role_id?: string;
  can_be_external?: boolean;
}

export type UserWithCompanyResponse = {
  user?: UserWithCompanyContent;
}

export type UserPagination = {
  next_page_cursor?: string;
  previous_page_cursor?: string;
  total_count?: number;
  page_size?: number;
  current_page?: number;
  last_page?: number;
  users?: UserWithCompanyContent[];
}

export type UserBody = {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  role?: string;
  company_id?: string;
  phone_verified?: boolean;
  can_download?: boolean;
}

export type UserBodyRequestSchema = {
  user?: UserBody;
};

// Bulk User
export type UserBulkBody = {
  id?: string;
  full_name?: string;
  email?: string;
  company_id?: string;
  company_name?: string;
  invited?: boolean;
  login_enabled?: boolean;
}

export type UserBulkCreateResponseSchema = {
  success_messages?: string[];
  success_users?: UserBulkBody[]
  error_messages?: string[];
  error_users?: UserBulkBody[];
  duplicate_messages: string[];
  duplicate_users?: UserBulkBody[]
}


//Invite 
export type UserInviteRequestBody = {
  user_ids?: string[];
}

export type UserInvite = {
  successes?: string[];
  errors?: string[];
}

// User Settings

//Site Roles
export type SiteRole = {
  id?: string;
  name?: string;
}

export type SiteRoleResponse = {
  roles?: SiteRole[];
}

export enum USER_COLUMNS {
  COMPANY_NAME = 'company_name',
  FULL_NAME = 'full_name',
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
  INVITED = 'invited',
  DATE_CREATED = 'date_created',
  ACTIONS = 'actions',
  ROLE_NAME = 'role_name',
}

export enum USER_FORM_VARIANT {
  EDIT = 'Edit',
  CREATE = 'Create',
}

