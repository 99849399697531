import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useNavigate } from 'react-router-dom';

// layouts
import { DashboardLayout, LogoOnlyLayout } from 'src/layouts';

// guards
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuard from 'src/guards/AuthGuard';
import { ROLE } from 'src/@types/user';
import RoleGuard from '../guards/RoleGuard';

// components
import { LoadingScreen, Page404 } from 'ca-react-component-lib';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  const navigate = useNavigate();

  return useRoutes([
    // Auth Routes
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
        {
          path: 'reset-password/:token',
          element: (
            <GuestGuard>
              <ResetPasswordConfirm />
            </GuestGuard>
          ),
        },
      ],
    },

    // App Routes
    {
      path: 'app',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/" replace /> },
        { path: '', element: <Navigate to="/app/sites" replace /> },
        { path: 'profile', element: <Profile /> },
        { path: 'todo', element: <Todo /> },
        { path: 'todo/:siteId', element: <Todo /> },
        { path: 'todo/:siteId/:assessmentId', element: <Todo /> },
        { path: 'sites', element: <Sites /> },
        {
          path: 'sites/add',
          element: <SitesAdd />,
        },
        { path: 'site/:siteId', element: <Site /> },
        { path: 'site/:id/edit', element: <EditSite /> },
        { path: 'site/:siteId/:assessmentId', element: <Site /> },
        {
          path: 'sites/bulk/add',
          element: <SitesBulkAdd />
        },
        { path: 'assessment/:id', element: <Assessment /> },
        { path: 'announcements', element: <Announcements /> },
        { path: 'announcement/create', element: <CreateAnnouncement /> },
        { path: 'announcement/:id/edit', element: <EditAnnouncement /> },
        { path: 'announcements', element: <Announcements /> },
        {
          path: 'processing',
          element: <Processing />,
        },
        { path: 'notifications', element: <Notifications /> },
      ],
    },

    // Management Routes
    {
      path: 'management',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to="/management/users" replace />,
        },
        {
          path: 'users',
          element: <Users />,
        },
        {
          path: 'users/bulk/add',
          element: <UsersBulkAdd />,
        },
        {
          path: 'user/add',
          element: <UserCreate />,
        },
        {
          path: 'user/:id/edit',
          element: <UserEdit />,
        },
        {
          path: 'manage-access',
          element: (
            <RoleGuard allowedRoles={[ROLE.INTERNAL_ADMIN]}>
              <ManageAccess />
            </RoleGuard>
          ),
        },
        {
          path: 'companies',
          element: <Companies />,
        },
        {
          path: 'company/add',
          element: <CompanyCreate />,
        },
        {
          path: 'company/:id/edit',
          element: <CompanyEdit />,
        },
        {
          path: 'tickets',
          element: <Tickets />,
        },
      ],
    },
    {
      path: 'tools',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'import-jobs', element: <ImportJobs /> },
        { path: 'export-jobs', element: <ExportJobs /> },
        {
          path: 'billing-report',
          element: (
            <RoleGuard
              allowedRoles={[
                ROLE.INTERNAL_ADMIN,
                ROLE.CUSTOMER_SUCCESS,
              ]}
            >
              <BillingReport />
            </RoleGuard>
          ),
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 onClick={() => navigate('/')} /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '/', element: <Navigate to="/app/sites" replace /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS
const Login = Loadable(lazy(() => import('src/pages/@auth/Login')));
const ResetPassword = Loadable(lazy(() => import('src/pages/@auth/ResetPassword')));
const ResetPasswordConfirm = Loadable(lazy(() => import('src/pages/@auth/ResetPasswordConfirm')));
const Profile = Loadable(lazy(() => import('src/pages/@app/Profile')));
const Todo = Loadable(lazy(() => import('src/pages/@dashboard/Todo')));
const Sites = Loadable(lazy(() => import('src/pages/@dashboard/Sites')));
const Site = Loadable(lazy(() => import('src/pages/@dashboard/Site')));
const Assessment = Loadable(lazy(() => import('src/pages/@dashboard/Assessment')));
const Companies = Loadable(lazy(() => import('../pages/@management/Companies')));
const EditSite = Loadable(lazy(() => import('src/pages/@dashboard/SiteEdit')));
const SitesAdd = Loadable(lazy(() => import('src/pages/@dashboard/SiteCreate')));
const Announcements = Loadable(lazy(() => import('src/pages/@dashboard/Announcements')));
const EditAnnouncement = Loadable(lazy(() => import('src/pages/@dashboard/AnnouncementEdit')));
const CreateAnnouncement = Loadable(lazy(() => import('src/pages/@dashboard/AnnouncementCreate')));
const CompanyCreate = Loadable(lazy(() => import('../pages/@management/CompanyCreate')));
const CompanyEdit = Loadable(lazy(() => import('../pages/@management/CompanyEdit')));
const ManageAccess = Loadable(lazy(() => import('src/pages/@management/ManageAccess')));
const Processing = Loadable(lazy(() => import('src/pages/@dashboard/Processing')));
const Notifications = Loadable(lazy(() => import('src/pages/@app/Notifications')));
const Users = Loadable(lazy(() => import('src/pages/@management/Users')));
const UserCreate = Loadable(lazy(() => import('src/pages/@management/UserCreate')));
const UserEdit = Loadable(lazy(() => import('src/pages/@management/UserEdit')));
const Tickets = Loadable(lazy(() => import('src/pages/@management/Tickets')));
const UsersBulkAdd = Loadable(lazy(() => import('../pages/@management/UsersBulkAdd')));
const ImportJobs = Loadable(lazy(() => import('src/pages/@tools/ImportJobs')));
const ExportJobs = Loadable(lazy(() => import('src/pages/@tools/ExportJobs')));
const SitesBulkAdd = Loadable(lazy(() => import('../pages/@dashboard/SitesBulkAdd')));
const BillingReport = Loadable(lazy(() => import('src/pages/@reports/Analytics/BillingReport')));
